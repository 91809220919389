// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academia-js": () => import("./../../../src/pages/academia.js" /* webpackChunkName: "component---src-pages-academia-js" */),
  "component---src-pages-artistas-js": () => import("./../../../src/pages/artistas.js" /* webpackChunkName: "component---src-pages-artistas-js" */),
  "component---src-pages-biotecnologia-js": () => import("./../../../src/pages/biotecnologia.js" /* webpackChunkName: "component---src-pages-biotecnologia-js" */),
  "component---src-pages-chat-js": () => import("./../../../src/pages/chat.js" /* webpackChunkName: "component---src-pages-chat-js" */),
  "component---src-pages-historia-js": () => import("./../../../src/pages/historia.js" /* webpackChunkName: "component---src-pages-historia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pano-js": () => import("./../../../src/pages/pano.js" /* webpackChunkName: "component---src-pages-pano-js" */),
  "component---src-templates-artist-js": () => import("./../../../src/templates/artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-biotecnologia-js": () => import("./../../../src/templates/biotecnologia.js" /* webpackChunkName: "component---src-templates-biotecnologia-js" */)
}

